


































































import { Component, Prop, PropSync } from 'vue-property-decorator'
import { Authentication, AuthServiceType } from '@movecloser/front-core'

import { AuthControlServiceType, IAuthControl } from '../../../../auth/contracts'
import { UserModel } from '../../../../auth/shared'
import { Inject } from '../../../../../support/plugins/front-core'

import { BuyerContextSwitch } from '../BuyerContextSwitch.vue'
/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'LoginAndRegister' })
export class LoginAndRegister extends BuyerContextSwitch {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Prop({ type: Boolean, required: false, default: true })
  public readonly withSocials!: boolean

  @PropSync('mode', { type: String, required: true })
  public readonly _mode!: string

  public socialPending: boolean = false

  /**
   * Callback to sign with facebook.
   */
  public async socialLogin (social: string): Promise<void> {
    if (typeof this.authControl.socialLogin !== 'function') {
      return
    }
    // TODO after launch
    try {
      // this.cleanError()
      this.socialPending = true

      const userSession = await this.authControl.socialLogin(social)

      this.authService.setToken(userSession)
      this.authService.setToken(userSession)
    } catch (error) {
      // this.setError(error as Error)
    } finally {
      this.socialPending = false
    }
  }
}
export default LoginAndRegister
