

















import { Component, Watch } from 'vue-property-decorator'

import { logger } from '../../../../../support'

import PaymentBaseDriver from './BaseDriver.vue'
import { Przelewy24ExcludedMethods } from '../PaymentStep.config'

/**
 * Driver for `przelewy24`.
 *
 * @summary - This driver loads all available payment methods in P24
 *            and allows you to select one.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Przelewy24General>({
  name: 'Przelewy24General'
})
export class Przelewy24General extends PaymentBaseDriver {
  public paymentMethods = []
  public selectedPaymentMethodId: number | null = null

  /**
   * Determines possible payments methods that should be available.
   */
  public get possiblePaymentMethods () {
    return this.paymentMethods.filter((method: any) => {
      return !Przelewy24ExcludedMethods.includes(method.id)
    })
  }

  public selectMethod (methodId: number): void {
    this.selectedPaymentMethodId = methodId
  }

  /**
   * @override
   */
  protected selectCandidate (): void {
    this.loadPaymentsMethods()
  }

  private async loadPaymentsMethods (): Promise<void> {
    try {
      this.$emit('saving', true)
      this.paymentMethods = await this.checkoutService.loadPaymentMethods(this.cart.total.value)
    } catch (e) {
      logger(e, 'error')
    } finally {
      this.$emit('saving', false)
    }
  }

 @Watch('selectedPaymentMethodId')
  private onSelectMethod (): void {
    this.setPaymentMethod([this.cart.id, { code: this.method.id }], {
      type: this.method.id,
      data: {
        method: this.selectedPaymentMethodId,
        regulationAccept: false
      }
    })
  }
}
export default Przelewy24General
