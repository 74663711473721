import { VueConstructor } from 'vue'

import { PaymentBaseDriver } from './BaseDriver.vue'
import { Przelewy24Blik } from './Przelewy24Blik.vue'
import { Przelewy24General } from './Przelewy24General.vue'
import { Przelewy24Google } from './Przelewy24Google.vue'
import { Przelewy24SingleMethod } from './Przelewy24SingleMethod.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultPaymentDriver: VueConstructor = PaymentBaseDriver

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum PaymentDriver {
  Przelewy24Blik = 'przelewy24_blik',
  Przelewy24General = 'przelewy24',
  Przelewy24SingleMethod = 'przelewy24_single_method',
  // Przelewy24Google = 'przelewy24_google_pay',
  // Przelewy24Apple = 'przelewy24_apple_pay'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const paymentsDriversRegistry: Record<string, VueConstructor> = {
  [PaymentDriver.Przelewy24General]: Przelewy24General as VueConstructor,
  [PaymentDriver.Przelewy24Blik]: Przelewy24Blik as VueConstructor,
  [PaymentDriver.Przelewy24SingleMethod]: Przelewy24SingleMethod as VueConstructor
  // [PaymentDriver.Przelewy24Google]: Przelewy24Google as VueConstructor
}
