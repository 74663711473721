
































































import { Component, Mixins, PropSync, Watch } from 'vue-property-decorator'

import { AuthMixin, IAuthMixin, ILogoutMixin, LogoutMixin } from '../../../auth/shared'
import { GuestDetailsFormData } from '../../molecules/GuestDetailsFormFieldset'
import { IUserMixin, UserMixin } from '../../../profile/shared'
import { Loader } from '../../../shared/molecules/Loader/Loader.vue'
import { LoginForm } from '../../../auth/organisms/LoginForm'
import { RequestResetLinkForm } from '../../../auth/organisms/RequestResetLinkForm'

import { RouteName } from '../../routes'
import { StepProps } from '../../molecules/Step/Step.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<BuyerContextSwitch>({
  name: 'BuyerContextSwitch',
  components: { Loader, LoginForm, RequestResetLinkForm },
  mounted () {
    this.isLoading = this.isWaitingForAuth
    if (!this.isLoading) {
      this.setUser(this.isLoggedInUser)
    }
  }
})
export class BuyerContextSwitch extends Mixins<IAuthMixin, ILogoutMixin, IUserMixin>(
  AuthMixin, LogoutMixin, UserMixin) {
  /**
   * @see StepProps.payload
   */
  @PropSync('payload', { type: Object, required: false })
  public _payload?: StepProps['payload']

  public isAnonymous: boolean = !this.isLoggedInUser
  public isForgotPassword: boolean = false
  public isLoading: boolean = true

  public async onLogout (): Promise<void> {
    await this.logout()
    await this.$router.push({ path: '/' })
  }

  public onToggleForgotPassword (): void {
    this.isForgotPassword = !this.isForgotPassword
  }

  @Watch('isWaitingForAuth')
  public setLoading () {
    this.isLoading = false
  }

  @Watch('isLoggedInUser')
  public setUser (isUserLoggedIn: boolean) {
    const user: GuestDetailsFormData | null = {
      ...(isUserLoggedIn ? this.user : this._payload?.user)
    }
    this._payload = { ...this._payload, user: { ...user }, isUserLoggedIn }
  }
}

export default BuyerContextSwitch
