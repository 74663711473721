











































































































































import { Component, Prop, PropSync } from 'vue-property-decorator'
import set from 'lodash/set'

import { CheckoutPayload } from '../../contracts'
import { GuestDetailsFormFieldset } from './GuestDetailsFormFieldset.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<GuestDetailsFormFieldsetTwo>({
  name: 'GuestDetailsFormFieldsetTwo',
  mounted (): void {
    // Set company if present.
    if ((this._formData.address.invoice?.company && this._formData.address.invoice?.company.length > 0) &&
      (this._formData.address.invoice?.nip && this._formData.address.invoice?.nip.length > 0)
    ) {
      this.isCompany = true
    }
  }
})
export class GuestDetailsFormFieldsetTwo extends GuestDetailsFormFieldset {
  /**
   * @override
   */
  @PropSync('formData', { type: Object, required: true })
  protected _formData!: CheckoutPayload

  @Prop({ type: Boolean, required: false, default: false })
  public readonly withRegistrationFields!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public readonly withCompanyCheckboxFields!: boolean

  public isCompany: boolean = false

  public get company (): string {
    if (!this.isCompany) {
      return this._formData.address.company
    } else {
      return this._formData.address.invoice?.company ?? ''
    }
  }

  public set company (val: string) {
    this.updateModel('address.company', val)

    if (this.isCompany) {
      this.updateModel('address.invoice.company', val)
    }
  }

  public onIsCompanyUpdate (value: boolean): void {
    this.isCompany = value
    if (!value) {
      const formDataCopy = { ...this._formData }

      delete formDataCopy.address.invoice

      this._formData = formDataCopy
    } else {
      this.updateModel('address.invoice.nip', '')
      this.updateModel('address.invoice.company', this._formData.address.company || '')
    }

    this.$emit('hasInvoice', value)
  }

  public onPasswordChange (value: string): void {
    this._formData.user.password = value
    if (value && value.length > 0) {
      this.onSignupChange(true)
    } else {
      this.onSignupChange(false)
    }
  }

  /**
   * Updates model (works with nested objects reactivity (reference))
   * @param key - `first.second.third.x`
   * @param value - `value for key`
   */
  public updateModel (key: string, value: unknown): void {
    const newFormData = { ...this._formData }

    set(newFormData, key, value)

    this._formData = newFormData

    this.$emit('update:errors')
  }
}

export default GuestDetailsFormFieldsetTwo
