






































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import { FormErrorsMixin } from '../../../../support/mixins'

import { GuestDetailsFormFieldsetProps as Data } from './GuestDetailsFormFieldset.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<GuestDetailsFormFieldset>({
  name: 'GuestDetailsFormFieldset',
  created () {
    this.model = this.formData
  }
})
export class GuestDetailsFormFieldset extends Mixins(FormErrorsMixin) {
  @Prop({ type: Object, required: false, default: null })
  public errors!: Data['_errors']

  @Prop({ type: Object, required: true })
  protected formData!: Data['_formData']

  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly pages!: Record<string, string>

  public model: Data['_formData'] = this.formData
  public signUp: boolean = false

  /**
   * Handles @model.sync event in check.
   * @param value
   */
  public onSignupChange (value: boolean): void {
    this.$emit('signupChanged', value)
  }

  @Watch('model', { deep: true })
  protected onModelChange (value: Data['_formData']): void {
    this.$emit('update:formData', value)
    this.$emit('update:errors')
  }
}

export default GuestDetailsFormFieldset
