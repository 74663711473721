import { AnyObject } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const PAYMENT_STEP_CONFIG_KEY = 'PaymentStep'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const PRZELEWY24 = 'przelewy24'

// TODO restore is before merge
/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
// export const defaultConfig: AnyObject = {
//   drivers: ['cashondelivery', 'paynow_gateway', 'banktransfer'],
//   icons: {
//     cashondelivery: require('./assets/images/przy-odbiorze.svg'),
//     paynow_gateway: require('./assets/images/paynow.svg'),
//     banktransfer: require('./assets/images/przelew.png')
//   }
// }

export const defaultConfig: AnyObject = {
  drivers: ['cashondelivery', 'ngeniusonline', 'banktransfer'],
  icons: {
    cashondelivery: require('./assets/images/przy-odbiorze.svg'),
    banktransfer: require('./assets/images/przelew.png'),
    ngeniusonline: require('./assets/images/ngeniusonline.png')
  }
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const Przelewy24ExcludedMethods: number[] = [154, 178]
