





























import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'
import { Component, Mixins } from 'vue-property-decorator'

import {
  AvailablePaymentMethod,
  CartItemData,
  ISiteService,
  PaymentMethodCode,
  PaymentMethodPayload,
  ShippingCarrierCode,
  SiteServiceType
} from '../../../../contexts'

import { Loader } from '../../../shared/molecules/Loader/Loader.vue'
import { Inject, logger } from '../../../../support'
import { StructureConfigurable } from '../../../../support/mixins'

import { CartMutationTypes, CheckoutStepCallback } from '../../contracts'

import { AbstractStep } from '../AbstractStep'
import {
  defaultConfig,
  PAYMENT_STEP_CONFIG_KEY,
  PRZELEWY24,
  Przelewy24ExcludedMethods
} from './PaymentStep.config'
import { defaultPaymentDriver, paymentsDriversRegistry } from './drivers/drivers'
import { PaymentMethod } from './PaymentStep.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<PaymentStep>({
  name: 'PaymentStep',
  components: { Loader },
  created (): void {
    this.config = this.getComponentConfig(PAYMENT_STEP_CONFIG_KEY, defaultConfig)
    this.loadAvailablePaymentMethods(this.cart.id)
  }
})
export class PaymentStep extends Mixins(AbstractStep, StructureConfigurable) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public errors: string[] = []
  public isLoading: boolean = false

  // public selectedPayment: PaymentMethodPayload | null = this.payload.payment

  public get selectedPayment (): PaymentMethodPayload | null {
    return this.payload.payment
  }

  public set selectedPayment (payment: any) {
    this.onChange('payment', payment)
  }

  public callbacks: CheckoutStepCallback[] = []

  public availablePaymentMethods: AvailablePaymentMethod[] = []

  public get selectedPaymentCode (): PaymentMethodCode | string | null {
    if (this.payload.payment && this.payload.payment.code === PRZELEWY24 &&
        this.payload.payment.methodId && Przelewy24ExcludedMethods.includes(Number(this.payload.payment.methodId))) {
      return String(this.payload.payment.methodId)
    }
    return this.payload.payment?.code ?? null
  }

  public get isDisabled (): boolean {
    return !this.isValidStep()
  }

  public get supportedPaymentMethods (): string[] {
    let methods = this.getConfigProperty<string[]>('drivers')

    methods = this.availablePaymentMethods.filter(({ code }) => {
      return methods.includes(code)
    }).map(({ code }) => code)

    if (this.payload.shipping && this.payload.shipping.carrierCode === ShippingCarrierCode.Paczkomat) {
      return methods.filter((m) => m !== PaymentMethodCode.OnDelivery)
    }

    return methods
  }

  public get supportedPaymentMethodsIcons (): AnyObject {
    return this.getConfigProperty<AnyObject>('icons')
  }

  public get methods (): PaymentMethod[] {
    const unorderedMethods = this.availablePaymentMethods
      .filter(({ code }) => this.supportedPaymentMethods.includes(code))
      .map((method: PaymentMethodPayload<string>) => ({
        id: method.code,
        title: this.$t(`front.checkout.organisms.PaymentStep.method.${method.code}.title`),
        description: this.$t(`front.checkout.organisms.PaymentStep.method.${method.code}.description`),
        image: this.supportedPaymentMethodsIcons[method.code],
        driver: method.code in paymentsDriversRegistry ? paymentsDriversRegistry[method.code] : defaultPaymentDriver
      }))
    return this.supportedPaymentMethods.map((code) => unorderedMethods.filter((m) => m.id === code)[0])
  }

  public async loadAvailablePaymentMethods (cardId: string) {
    this.isLoading = true

    this.availablePaymentMethods = await this.checkoutService.loadAvailablePaymentMethods(cardId).finally(() => {
      this.isLoading = false
    })
  }

  public onModelUpdate (value: PaymentMethodCode | number): void {
    if (isFinite(Number(value)) && (Przelewy24ExcludedMethods.includes(Number(value)))) {
      this.selectedPayment = {
        ...this.availablePaymentMethods.find(method => method.code === PRZELEWY24),
        methodId: value
      } ?? null
    } else {
      this.selectedPayment = this.availablePaymentMethods.find(method => method.code === value) ?? null
    }
    this.$nextTick(this.submit)
  }

  public onSaving (value: boolean): void {
    this.$emit('saving', value)
  }

  public static isValidStep (payload: AnyObject): boolean {
    return payload.payment !== null &&
      typeof payload.payment.code === 'string' && payload.payment.code.length > 0
  }

  public async submit (): Promise<void> {
    if (!this.selectedPayment) {
      return
    }

    if (!this.isValidStep()) {
      return
    }

    this.errors = []

    try {
      this.$emit('saving', true)

      if (this.callbacks.length > 0) {
        let cart
        for (const c of this.callbacks) {
          const returned = await c.callable.apply(c.onThis, c.args)
          if (typeof returned === 'object' && returned !== null) {
            cart = returned
          }
        }

        this.$store.commit(CartMutationTypes.SetCart, cart)
      }

      const getFirstVariant = (item: CartItemData) => {
        return Object.values(item.product.variants)[0]
      }
      const getFirstVariantKey = (item: CartItemData) => {
        const key = Object.keys(item.product.variants)[0] ?? ''
        return (key !== '_') ? key : ''
      }

      this.eventBus.emit(
        'app:checkout.addPaymentInfo',
        {
          currency: this.cart.total.currency,
          value: this.cart.total.value,
          coupon: (this.cart.coupons[0]) ? this.cart.coupons[0].code : '',
          shippingTier: this.cart.selectedShippingMethod?.methodCode,
          paymentMethod: this.selectedPayment.code,
          items: this.cart.items.map((item) => {
            const variant = getFirstVariant(item)
            const variantSlug = getFirstVariantKey(item)
            return {
              id: variant.sku,
              parent_id: item.product.sku,
              variant: variantSlug,
              name: variant.name,
              category: variant.attributes.mainCategory,
              brand: variant.attributes.brand,
              currency: this.cart.total.currency,
              price: variant.price.finalPrice,
              quantity: item.quantity,
              url: this.siteService.getActiveSiteAddress() + `${item.product.urlPath}`,
              image: item.product.variants && (Array.isArray(variant.images) &&
                variant.images.length > 0)
                ? this.siteService.getActiveSiteAddress() +
                variant.images[0].url : ''
            }
          })
        }
      )
    } catch (e) {
      logger(e, 'warn')
      this.showToast((e as Error).message, 'warning')
    } finally {
      this.$emit('saving', false)
    }
  }

  public goToNextStep (): void {
    if (!this.payload.payment || this.payload.payment.code.length === 0) {
      this.errors = [this.$t('front.checkout.organisms.PaymentStep.error').toString()]
      return
    }

    this.nextStep()
  }

  protected isValidStep (): boolean {
    return this.selectedPayment !== null && this.selectedPayment.code.length > 0
  }
}

export default PaymentStep
