





























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import {
  AvailableShippingMethod,
  ShippingCarrierCode,
  ShippingMethod,
  ShippingMethodCode
} from '../../../../contexts'

import { CheckoutServiceType, ICheckoutService } from '../../services/checkout'
import { CartModel, CheckoutStepCallback } from '../../contracts'

import { defaultDriver, driversRegistry } from './drivers/drivers'
import {
  getShipmentName,
  isFreeShipmentThresholdReached,
  shippingIcon
} from './Shippings.helpers'
import { Inject } from '../../../../support'
import { Loader } from '../../../shared/molecules/Loader'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Shippings>({
  name: 'Shippings',
  components: {
    Loader
  },
  mounted () {
    this.loadAvailableShippingMethods(this.cart.id)
  }
})
export class Shippings extends Vue {
  @Prop({ type: Array, required: true })
  protected readonly availableMethods!: string[]

  @PropSync('errors', { type: Array, required: false, default: () => ([]) })
  public _errors!: string[]

  @PropSync('selected', { type: Object, required: false, default: null })
  public _selected!: ShippingMethod | null

  @PropSync('callbacks', { type: Array, required: true })
  public _callbacks!: CheckoutStepCallback[]

  @Prop({ type: Object, required: true })
  protected readonly cart!: CartModel

  @Prop({ type: Boolean, required: false, default: false })
  protected readonly useCustomLabels!: boolean

  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  public availableShippingMethods: AvailableShippingMethod[] = []

  public isLoading = true

  public async loadAvailableShippingMethods (cardId: string) {
    this.isLoading = true

    this.availableShippingMethods = await this.checkoutService.loadAvailableShippingMethods(
      cardId).finally(() => {
      this.isLoading = false
    })
  }

  public get methods () {
    const considerFreeShipment: boolean = isFreeShipmentThresholdReached(this.availableShippingMethods.map((method) => method.methodCode))
    return this.availableShippingMethods
      .filter(({ methodCode }) => this.availableMethods.length ? this.availableMethods.includes(methodCode) : true)
      .filter((method) => {
        return considerFreeShipment ? method.price.value === 0 : true
      })
      .map((method: ShippingMethod) => ({
        data: {
          carrierCode: method.methodCode,
          description: this.useCustomLabels
            ? this.$t(`front.checkout.organisms.ShippingStep.method.${method.methodCode}.description`)
            : '',
          image: shippingIcon[method.methodCode] ?? shippingIcon[method.carrierCode],
          price: method.price,
          title: getShipmentName(this.$t, method, this.useCustomLabels)
        },
        driver: method.methodCode in driversRegistry
          ? driversRegistry[method.methodCode]
          : defaultDriver,
        id: method.methodCode
      }))
  }

  public get selectedShippingCode (): ShippingMethodCode | null {
    return this._selected?.methodCode ?? null
  }

  public set selectedShippingCode (value: ShippingMethodCode | null) {
    this._selected = this.availableShippingMethods.find(method => method.methodCode === value) ?? null
    this._errors = []
  }
}
export default Shippings
